
import { retrieveButter,
         getIOSDevice } from '~/utils/butterUtils'
export default {
  name: '',
  data () {
    return {
      showCFM: false,
      showData: false,
      showDict: false,
      showSBWarning: true,
      showSimulatedStudent: false,
      showTimeWarp: false,
      monthNames: [
        "select Month", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
      ],
      monthDays: [
        0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31,
      ],
      indexOfListOfYears: 0,
      indexOfMonthNames: 0,
      indexOfListOfDays: 0,
      indexOfListOfHours: 0,
      latestYear: new Date().getFullYear()+2,
      earliestYear: new Date().getFullYear()-2,
      daysInMonth: 31
    }
  },
  async asyncData ({ store }) {
    await retrieveButter(store, 'pages/about-us.vue asyncData ')
  },
  computed: {
    dateFormat () { return this.$i18n.locales.filter(item => item.code === this.$i18n.locale)[0].iso },

    showCodeFragmentMark () { return this.$store.state.showCodeFragmentMark },
    showDictionaryKeys () { return this.$store.state.showDictionaryKeys },
    showIsSimulatedStudentSite () { return this.$store.state.isSimulatedStudentSite },
    showSandboxWarning () { return this.$store.state.showSandboxWarning},
    showTestData () { return this.$store.state.showTestData },

    isProductionSite () { return process.env.stage === 'prod' },

    appName () { return process.env.appName },
    appVersion () { return process.env.appVersion },
    autocompleteEndpoint () { return process.env.autocompleteEndpoint },
    autocompleteUrl () { return process.env.autocompleteUrl },
    baseUrl () { return process.env.baseUrl },
    branch () { return process.env.branch },
    commitRef () { return process.env.commitRef },
    detailsEndpoint () { return process.env.detailsEndpoint },
    dev () { return process.env.dev },
    devStage () { return process.env.devStage },
    isDemsSite () { return process.env.isDemsSite },
    isIOSDevice () { return getIOSDevice() },
    isStudentSite () { return process.env.isStudentSite },
    isSimulatedStudentSite () { return this.$store.state.isSimulatedStudentSite },
    isVrSite () { return process.env.isVrSite },
    mode () { return process.env.mode },
    offline () { return process.env.offline },
    placeDetailsUrl () { return process.env.placeDetailsUrl },
    placesUrl () { return process.env.placesUrl },
    siteName () { return process.env.siteName },
    siteTitle () { return process.env.siteTitle },
    stage () { return process.env.stage },
    url () { return process.env.url },
    userAgent () { return window.navigator.userAgent },

    cookies () { return document.cookie },

    listOfYears() {
      const arrayOfYears = ['select Year']
      for (let i = this.latestYear; i >= this.earliestYear; i--) {
        arrayOfYears.push(i)
      }
      return arrayOfYears
    },
    listOfDays () {
      const arrayOfDays = ['select Day']
      for (let i = 1; i <= this.monthDays[this.indexOfMonthNames]; i++) {
        arrayOfDays.push(i)
      }
      return arrayOfDays
    },
    listOfHours () {
      const arrayOfHours = ['select Hour']
      for (let i = 0; i < 24; i++) {
        arrayOfHours.push(i)
      }
      return arrayOfHours
    },

    butterA11yDictionary () { return this.$store.state.butterA11yDictionary },
    butterBanners () { return this.$store.state.butterBanners },
    butterDAGlobalPresidentialPrimary () { return this.$store.state.butterDAGlobalPresidentialPrimary },
    butterDictionary () { return this.$store.state.butterDictionary },
    butterLeos () { return this.$store.state.butterLeos },
    butterFaqCategories () { return this.$store.state.butterFaqCategories },
    butterFaqQuestions () { return this.$store.state.butterFaqQuestions },
    butterSiteInfo () { return this.$store.state.butterSiteInfo },
    butterStateElections () { return this.$store.state.butterStateElections },
    butterStateVotingRules () { return this.$store.state.butterStateVotingRules },
    butterStateVotingRulesFieldDescriptions () { return this.$store.state.butterStateVotingRulesFieldDescriptions },
    butterVFAGraphics () { return this.$store.state.butterVFAGraphics },
    butterVFAParameters () { return this.$store.state.butterVFAParameters },
    butterVolunteerData () { return this.$store.state.butterVolunteerData },
    butterVoterAssistance () { return this.$store.state.butterVoterAssistance },

  },
  created () {
    if (process.env.stage==='prod') {
      this.$router.push(this.localePath({ name: 'index' }))
    }
    if (parseInt(sessionStorage.getItem('timeWarpOffset'))!=0) {
      this.showTimeWarp = true
    }
  },
  mounted () {
    if (sessionStorage.getItem('timeWarpObj')) {
      let timeWarpObj = JSON.parse(sessionStorage.getItem('timeWarpObj'))
      this.indexOfListOfYears = this.listOfYears.indexOf(timeWarpObj.yyyy)
      this.indexOfMonthNames  = timeWarpObj.mmm
      this.indexOfListOfDays  = timeWarpObj.dd
      this.indexOfListOfHours = timeWarpObj.hh + 1
    }
  },
  methods: {
    doshowCFM () {
      this.showCFM = !this.showCFM
      this.$store.commit('saveShowCodeFragmentMark', this.showCFM)
    },
    doShowDict () {
      this.showDict = !this.showDict
      this.$store.commit('saveShowDictionaryKeys', this.showDict)
    },
    doShowSBWarning () {
      this.showSBWarning = !this.showSBWarning
      this.$store.commit('saveShowSandboxWarning', this.showSBWarning)
    },
    doShowSimulatedStudent () {
      this.showSimulatedStudent = !this.showSimulatedStudent
      this.$store.commit('saveIsSimulatedStudentSite', this.showSimulatedStudent)
    },
    doShowTest () {
      this.showData = !this.showData
      this.$store.commit('saveShowTestData', this.showData)
    },
    doShowTimeWarp () {
      this.showTimeWarp = !this.showTimeWarp

      if (!this.showTimeWarp) {
        this.doClearTimeWarpOffset()
      }
    },
    doClearTimeWarpOffset () {
      sessionStorage.setItem("timeWarpOffset", 0)

      this.indexOfListOfYears = 0
      this.indexOfMonthNames  = 0
      this.indexOfListOfDays  = 0
      this.indexOfListOfHours = 0

      let timeWarpObj = { 'yyyy': 0, 'mmm': 0, 'dd': 0, 'hh': 0, 'mm': 0, 'ss': 0}
      sessionStorage.setItem("timeWarpObj", JSON.stringify(timeWarpObj))
    },
    doSetTimeWarpOffset () {
      if (this.indexOfListOfYears>0 && this.indexOfMonthNames>0 && this.indexOfListOfDays>0 ) {
        const yyyy = this.listOfYears[this.indexOfListOfYears]
        const mmm  = this.indexOfMonthNames - 1
        const dd   = this.listOfDays[this.indexOfListOfDays]
        const hh   = this.indexOfListOfHours>0 ? this.indexOfListOfHours - 1 : 0

        const sysDate = new Date()
        const sysyyyy = sysDate.getFullYear()
        const sysmmm  = sysDate.getMonth()
        const sysdd   = sysDate.getDate()
        const syshh   = sysDate.getHours()

        /**
           if "entered date" != "system today",
           then use the "entered date"
           else clear the "entered date" 
         */
        if (yyyy != sysyyyy || mmm != sysmmm || dd != sysdd || hh != syshh ) {
          const mm = sysDate.getMinutes()
          const ss = sysDate.getSeconds()

          const offset = new Date(yyyy, mmm, dd, hh, mm, ss).getTime() - sysDate.getTime()
          sessionStorage.setItem("timeWarpOffset", offset)

          const timeWarpObj = { yyyy, 'mmm':mmm+1, dd, hh, mm, ss}
          sessionStorage.setItem("timeWarpObj", JSON.stringify(timeWarpObj))
        } else {
          this.doClearTimeWarpOffset()
        }
      } else {
        this.doClearTimeWarpOffset()
      }
    },
    changeYear(year) {
      this.indexOfListOfYears = this.listOfYears.indexOf(year)
    },
    changeMonth(month) {
      this.indexOfMonthNames = month
    },
    changeDay(day) {
      this.indexOfListOfDays = this.listOfDays.indexOf(day)
    },
    changeHour(hour) {
      this.indexOfListOfHours = this.listOfHours.indexOf(hour)
    }
  },
  watch: {
    indexOfListOfYears(value) {
      // 2022-12-16 John Yee: I'm not going to bother to program for years evenly divisible by 100 and 400.
      const leapYear = this.listOfYears[value] % 4 === 0
      this.monthDays[2] = (leapYear) ? 29 : 28

      if (this.indexOfMonthNames===2) {
        this.indexOfMonthNames = 0
        this.indexOfListOfDays = 0
      }
    },
  },
}
